import { render, staticRenderFns } from "./telepon.vue?vue&type=template&id=7263ef51&scoped=true"
import script from "./telepon.vue?vue&type=script&lang=js"
export * from "./telepon.vue?vue&type=script&lang=js"
import style0 from "./telepon.vue?vue&type=style&index=0&id=7263ef51&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7263ef51",
  null
  
)

export default component.exports
import {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QDialog,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QDialog,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
